import { IconSvg, LazyHydrate, extendableComponent, iconChevronDown } from '@graphcommerce/next-ui'
import {
  Container,
  Box,
  Typography,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
  GlobalStyles,
} from '@mui/material'
import { ContactInformationFragment } from '../GraphCMS/ContactInformation/ContactInformation.gql'
import { FooterBase } from './FooterBase'
import { FooterQueryFragment } from './FooterQueryFragment.gql'

export type FooterProps = FooterQueryFragment & ContactInformationFragment

const { classes, selectors } = extendableComponent('Footer', ['root', 'columns'] as const)

export function Footer(props: FooterProps) {
  const { footer } = props
  const theme = useTheme()

  const cookieBotGlobalStyles = (
    <GlobalStyles
      styles={{
        '& #CybotCookiebotDialog': {
          minWidth: 'unset !important',
          maxWidth: `calc(100% - ${theme.spacings.sm} * 2) !important`,
          borderRadius: `calc(${theme.shape.borderRadius} * 7) !important`,
          top: `unset !important`,
          transform: 'none !important',
          left: `${theme.spacings.sm} !important`,
          bottom: `${theme.spacings.sm} !important`,
        },
        '& #CybotCookiebotDialogPoweredbyCybot': {
          display: 'none !important',
        },
        ' & #CybotCookiebotDialogFooter': {
          alignSelf: 'center',
        },
        '& .CybotCookiebotDialogBodyBottomWrapper': {
          marginTop: '1em',
        },
        '& #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll': {
          borderRadius: '99em !important',
          lineHeight: '2em',
        },
        '& #CybotCookiebotDialogBodyLevelButtonCustomize, #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection':
          {
            border: 'none !important',
          },
      }}
    />
  )

  return (
    <LazyHydrate>
      <Box className={classes.root} sx={{ background: theme.palette.secondary.light }}>
        {cookieBotGlobalStyles}
        <Container maxWidth='lg' sx={{ py: theme.spacings.lg }}>
          <Box
            className={classes.columns}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            {footer?.footerMenuLists?.map((menu) => {
              if (!menu.pageLinks || menu.pageLinks.length < 1) return null
              const menuItems = menu.pageLinks

              return (
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{
                    backgroundColor: 'transparent',
                    '& .MuiAccordionSummary-root': { p: 0, cursor: { md: 'default' } },
                    '&:before': {
                      display: { md: 'none' },
                    },
                    [theme.breakpoints.up('md')]: {
                      '& .MuiCollapse-root.MuiCollapse-vertical': {
                        visibility: 'visible !important',
                        height: 'auto !important',
                        minHeight: 'unset !important',
                        overflow: 'visible !important',
                        transition: 'none !important',
                      },
                    },
                  }}
                  key={menu.id}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconSvg
                        size='medium'
                        src={iconChevronDown}
                        sx={{ display: { sm: 'flex', md: 'none' } }}
                      />
                    }
                  >
                    {menu.title && (
                      <Typography variant='h6' component='div'>
                        {menu.title}
                      </Typography>
                    )}
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, pb: { xs: theme.spacings.xxs, md: 0 } }}>
                    <Box component='ul' sx={{ p: 0, m: 0, listStyle: 'none' }}>
                      {menuItems?.map((link) => (
                        <li key={link.id}>
                          <Link
                            variant='body1'
                            href={link.url}
                            color='textPrimary'
                            underline='hover'
                            sx={{ lineHeight: '2.5em' }}
                          >
                            {link.title}
                          </Link>
                        </li>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Box>
        </Container>
        <FooterBase footer={footer} />
      </Box>
    </LazyHydrate>
  )
}

Footer.selectors = selectors
