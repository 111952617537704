import { breakpointVal } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { FooterQueryFragment } from '../Layout/FooterQueryFragment.gql'
import { PaymentLogo } from './PaymentLogo'

type Props = Partial<Pick<NonNullable<FooterQueryFragment['footer']>, 'paymentMethods'>> & {
  sx?: SxProps<Theme>
}

export function PaymentMethods(props: Props) {
  const { paymentMethods, sx = [] } = props

  if (!paymentMethods || paymentMethods.length === 0) return null

  return (
    <Box
      component='ul'
      sx={[
        (theme) => ({
          ...theme.typography.body2,
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: theme.spacings.xs,
          alignItems: 'center',
          padding: `calc(${theme.spacings.xs} * 0.85)`,
          backgroundColor: 'background.image',
          justifyContent: { xs: 'start', md: 'center' },
          marginBottom: 0,

          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 3,
            theme.shape.borderRadius * 5,
            theme.breakpoints.values,
          ),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography component='span' variant='inherit'>
        <Trans id='Secure Payment' />
      </Typography>
      {paymentMethods?.map((item) => {
        const asset = item?.asset
        if (!asset) return false

        return (
          <Box key={asset?.url} component='li' sx={{ display: 'inline-flex' }}>
            <PaymentLogo logo={asset} />
          </Box>
        )
      })}
    </Box>
  )
}
