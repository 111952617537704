import { Image } from '@graphcommerce/image'
import { FooterIconFragment } from './FooterIconFragment.gql'

export function FooterIcon(props: FooterIconFragment) {
  const { footer } = props
  const url = footer?.footerIcon?.url

  if (!footer || !url) return null

  return (
    <Image
      layout='fill'
      src={url}
      unoptimized
      alt='footerIcon'
      sx={{ height: '48px', m: 'auto' }}
    />
  )
}
