import { useQuery } from '@graphcommerce/graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { Logo as LogoBase, useStorefrontConfig } from '@graphcommerce/next-ui'

export function Logo() {
  const { logo } = useStorefrontConfig()
  const { data: config } = useQuery(StoreConfigDocument)

  const storeName = config?.storeConfig?.store_name

  if (!logo) {
    if (process.env.NODE_ENV !== 'production') {
      throw Error('Logo is missing. Please define a storefront logo in your configuration file.')
    }
    return null
  }

  return (
    <LogoBase
      sx={(theme) => ({
        margin: 'unset !important',
        '& .GcLogo-logo': {
          width: 'auto',
          height: {
            xs: '35px',
            md: `calc(${theme.appShell.appBarHeightMd} - ${theme.spacings.sm} )`,
          },
          paddingLeft: { xs: 0, md: 0 },
          filter: theme.palette.mode === 'dark' ? 'invert(100%)' : 'none',
        },
      })}
      image={{ alt: `${storeName} logo`, src: logo, unoptimized: true, layout: 'fill', height: 45 }}
    />
  )
}
