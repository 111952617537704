import { AssetFragment } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'

export function PaymentLogo(props: { logo?: AssetFragment }) {
  const { logo } = props

  if (!logo) return null

  return (
    <Image
      src={logo.url}
      height={logo.height ?? 80}
      alt={logo.alt ?? ''}
      unoptimized
      sx={{
        width: () => {
          const widthBase = 21
          const scaleFactor = 0.525
          const naturalWidth = logo.width || 0
          const naturalHeight = logo.height || 0
          const imageRatio = naturalWidth / naturalHeight
          const width = imageRatio ** scaleFactor * widthBase
          return { xs: width * 0.8, lg: width }
        },
        display: 'block',
      }}
    />
  )
}
