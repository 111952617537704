import { Scroller, ScrollerButton, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { Image } from '@graphcommerce/image'
import { IconSvg, extendableComponent } from '@graphcommerce/next-ui'
import { iconChevronRight, iconEmail } from '@graphcommerce/next-ui/icons'
import { Trans } from '@lingui/react'
import { Box, Button, Container, IconButton, Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { PaymentMethods } from '../PaymentMethods/PaymentMethods'
import { FooterIcon } from './FooterIcon'
import { FooterQueryFragment } from './FooterQueryFragment.gql'

export type FooterProps = FooterQueryFragment

export function FooterBase(props: FooterProps) {
  const { footer } = props
  const theme = useTheme()

  const { classes } = extendableComponent('Footer', [
    'root',
    'socials',
    'payment',
    'storeSwitcher',
    'buttons',
    'links',
    'scroller',
    'icon',
    'right',
    'button',
  ] as const)

  if (!footer) return null
  const { copyright, socialLinks, legalLinks } = footer

  return (
    <Container
      sx={{
        background: {
          xs: `linear-gradient(180deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
          md: `linear-gradient(90deg, ${theme.palette.primary.dark} 28%, ${theme.palette.primary.main} 74%)`,
        },
        display: 'grid',
        px: { xs: theme.spacings.lg, md: theme.spacings.sm },
        py: { xs: theme.spacings.lg, md: '10px' },
        gridTemplateAreas: `'icon links socials payment buttons'`,
        columnGap: theme.spacings.sm,
        alignItems: 'center',
        gridTemplateColumns: 'repeat(4, auto) 1fr',
        justifyContent: { xs: 'center', md: 'start' },

        [theme.breakpoints.down('lg')]: {
          gridTemplateAreas: `'icon links links socials socials' 'payment payment buttons buttons buttons'`,
          gap: theme.spacings.xxs,
        },

        [theme.breakpoints.down('md')]: {
          gridTemplateAreas: `'icon icon icon icon icon' 'buttons buttons buttons buttons buttons' 'socials socials socials socials socials' 'payment payment payment payment payment' 'links links links links links'`,
          gap: theme.spacings.md,
        },
      }}
      maxWidth={false}
      className={classes.root}
    >
      <Box sx={{ gridArea: 'icon', lineHeight: 0, textAlign: 'center' }} className={classes.icon}>
        <FooterIcon footer={footer} />
      </Box>
      <ScrollerProvider scrollSnapAlign='none'>
        <Box
          sx={{
            gridArea: 'links',
            color: 'white',
            typography: 'body2',
            display: 'grid',
            gridAutoFlow: { xs: 'row', md: 'column' },
            textAlign: { xs: 'center', md: 'flex-end', lg: 'left' },
            '& .Scroller-root.grid': {
              gridAutoFlow: { xs: 'row', md: 'column' },
              gridAutoRows: { xs: 'auto', md: '40%' },
              m: { xs: 'auto', md: 'unset' },
            },
          }}
          className={classes.links}
        >
          <Scroller
            sx={{
              gridArea: `1 / 1 / 1 / 4`,
              gap: theme.spacings.xs,
              overflow: 'hidden',
              gridAutoColumns: 'max-content',
              alignItems: 'center',
            }}
            hideScrollbar
          >
            {copyright}
            {legalLinks?.map((link) => (
              <Link key={link.title} href={link.url} color='white' underline='hover'>
                {link.title}
              </Link>
            ))}
          </Scroller>
          <ScrollerButton
            sxContainer={{
              gridArea: `1 / 3 / 1 / 4`,
            }}
            sx={{
              pointerEvents: 'all',
              boxShadow: 'none',
              height: 48,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: `${theme.palette.primary.dark} !important`,
              '& svg': {
                color: 'white',
              },
            }}
            direction='right'
            size='small'
            className={`${classes.right} ${classes.button}`}
          >
            <IconSvg src={iconChevronRight} />
          </ScrollerButton>
        </Box>
      </ScrollerProvider>
      <Box
        className={classes.socials}
        sx={{
          gridArea: 'socials',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-end', lg: 'flex-start' },
          gap: theme.spacings.xxs,
          pl: '12px',
        }}
      >
        {socialLinks?.map((link) => (
          <IconButton key={link.title} href={link.url} color='inherit' size='medium' edge='start'>
            {link.asset ? (
              <Image
                layout='fixed'
                src={link.asset.url}
                width={24}
                height={24}
                unoptimized
                alt={link.title}
                sx={{
                  filter: theme.palette.mode === 'dark' ? 'invert(0%)' : 'invert(100%)',
                }}
              />
            ) : (
              link.title
            )}
          </IconButton>
        ))}
      </Box>
      <Box
        className={classes.payment}
        sx={{
          gridArea: 'payment',
          display: 'flex',
          justifyContent: 'flex-start',
          justifySelf: { xs: 'center', md: 'flex-start' },
          gap: theme.spacings.xxs,
        }}
      >
        <PaymentMethods
          paymentMethods={footer?.paymentMethods}
          sx={{
            backgroundColor: theme.palette.primary.light,
            mt: 0,
            py: '12px',
            borderRadius: { xs: '99em' },
            '& span': { display: 'none' },
            justifyContent: { xs: 'center' },
          }}
        />
      </Box>
      <Box
        sx={{ gridArea: 'buttons', justifySelf: { xs: 'center', md: 'flex-end' } }}
        className={classes.buttons}
      >
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacings.xxs,
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Button
            href='/newsletter'
            variant='pill'
            startIcon={<IconSvg key='icon' src={iconEmail} size='inherit' />}
            sx={{ background: theme.palette.primary.dark, color: 'white' }}
          >
            <Trans id='Newsletter' />
          </Button>
          <Button
            href='/service'
            variant='pill'
            color='inherit'
            sx={{ color: theme.palette.text.primary, '&:hover': { color: 'white' } }}
          >
            <Trans id='Customer Service' />
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
