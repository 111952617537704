import { Fab, IconSvg, iconCirle } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Button, Container, GlobalStyles } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import iconChatBubble from '../Icons/chat-bubble.svg'

const TextFab = () => (
  <Button
    color='primary'
    variant='pill'
    onClick={(e) => {
      e.preventDefault()
      globalThis.Trengo?.Api.Widget.open('chat')
    }}
    sx={{
      py: 0.5,
      px: 2.5,
      display: { xs: 'none', sm: 'flex' },
      gap: '4px',
    }}
  >
    <IconSvg src={iconCirle} size='xs' />
    <Trans id='How can we help you?' />
  </Button>
)

export function ChatFab() {
  const theme = useTheme()

  const trangoWidgetGlobalStyles = (
    <GlobalStyles
      styles={{
        '& body .TrengoWidgetLauncher__iframe': {
          display: 'none',
        },
        '& body .TrengoWidgetGreeter__iframe': {
          [theme.breakpoints.down('md')]: {
            bottom: `calc(${theme.spacings.xxl} * 2)`,
          },
        },
        '& body .TrengoOnlineStatus__iframe': {
          display: 'none',
        },
      }}
    />
  )

  return (
    <>
      {trangoWidgetGlobalStyles}
      <Container
        sx={{
          position: { xs: 'fixed', md: 'sticky' },
          right: { xs: '20px', sm: theme.spacings.sm },
          marginTop: { md: `calc(${theme.spacings.lg} * -1)` },
          marginBottom: { md: theme.spacings.xxs },
          bottom: { xs: theme.spacings.xxl, md: theme.spacings.sm },
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacings.xxs,
          mr: 0,
          px: { xs: 0 },
        }}
      >
        <TextFab />
        <Fab
          icon={iconChatBubble}
          color='primary'
          onClick={(e) => {
            e.preventDefault()
            globalThis.Trengo?.Api.Widget.open('chat')
          }}
          sx={{
            '& svg.IconSvg-root': {
              stroke: 'none',
              width: { xs: '32px', md: '44px' },
              height: { xs: '32px', md: '44px' },
            },
          }}
        />
      </Container>
    </>
  )
}
